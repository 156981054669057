import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.scss'; // https://create-react-app.dev/docs/adding-a-sass-stylesheet
import cn from 'classnames';

function getApiUrl() {
  var hostname
  if (window.location.hostname === 'localhost') {
    // Use this if you want to run condenser locally
    // hostname = 'localhost:5115'
    // proxy field in package.json should make this work ok
    // console.log('getApiUrl');
    // return '/text';
    // hostname = window.location.hostname;
    return '/text';
  } else {
    // condenser-stage.emeraldcloudlab.com -> snaptext-stage.emeraldcloudlab.com
    hostname = window.location.hostname.replace('condenser', 'snaptext');
  }
  // Protocol agnostic for our older machines
  return `//${hostname}/text`;
}

//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
// BoxedInput
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
function InputBox(props) {
  return (
    <input
      className="input-box"
      type={props.type}
      onKeyDown={props.onKeyDown}
      onChange={props.onChange}
      onFocus={props.onFocus}
      maxLength='1'
      name={props.name}
      autoComplete="off"
    />
  );
}
function setFocusPrev(target) {
  if (target.previousElementSibling !== null) {
    target.previousElementSibling.focus()
  }
}
function setFocusNext(target) {
  if (target.nextElementSibling !== null) {
    target.nextElementSibling.focus()
  }
}
function BoxedInput(props) {
  const [
    characterArray,
    setCharacterArray,
  ] = useState(Array(props.amount).fill(null));

  const onKeyDown = useCallback(({ target, key }) => {
    console.log('keydown');
    if (key === 'Backspace') {
      if (target.value === '' && target.previousElementSibling !== null) {
        target.previousElementSibling.value = ''
        setFocusPrev(target)
      } else {
        target.value = ''
      }
      // this.setModuleOutput(target)
    } else if (key === 'ArrowLeft') {
      setFocusPrev(target)
    } else if (key === 'ArrowRight') {
      setFocusNext(target)
    } else if (key === 'Enter') {
      props.onEnter();
    }
  }, [props]);

  const onFocus = useCallback(({ target }) => {
    // var el = target
    // setTimeout(function () {
    //   el.select()
    // }, 0)
  }, []);

  const onChange = useCallback(({ target }) => {
    const index = target.name.replace('input', '');
    if (target.value.match(props.inputRegExp)) {
      setFocusNext(target);

      const newArray = characterArray.slice();
      newArray[index] = target.value;
      setCharacterArray(newArray);

      const newValue = newArray.join('');
      if (newValue.length !== 6) {
        props.onChange('');
      } else {
        const val = `${newValue.slice(0, 3)} ${newValue.slice(3, 6)}`;
        props.onChange(val.toUpperCase());
      }
    } else {
      target.value = characterArray[target.name.replace('input', '')]
    }
  }, [characterArray, props]);

  useEffect(() => {
    // if (this.props.autoFocus) {
    //   this.inputElements['input0'].select()
    // }
  }, []);

  console.log('characterArray', characterArray);

  return (
    <div className="boxed-input">
      {(() => {
        let items = []
        for (var i = 0; i < props.amount; i++) {
          items.push(
            <InputBox
              type={props.password ? 'password' : 'text'}
              key={i}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              onChange={onChange}
              name={`input${i}`}
            />
          )
        }
        return items
      })()}
    </div>
  )
}

//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
// App
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
//-----------------------------------------------------------------------------
async function fetchText(snapId) {
  const url = `${getApiUrl()}/${snapId}`;
  return fetch(url).then((resp) => {
    return resp.json()
  }).then((snapJSON) => {
    console.log('snapJSON', snapJSON);
    return snapJSON;
  });
}

// NOTE: you can't set the clipboard on a timer, only while handling a specific user action
// like a mouse click or keypress.
// So we will just reset clipboard if they click on reload, or "Enter"
// Note that we can't seem to put nothing on the clipboard, like space or no chars.
// So we'll put "DOH!"
function emptyClipboard(emptyTextRef) {
  console.log('emptyClipboard');
  const range = document.createRange();
  range.selectNodeContents(emptyTextRef.current);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand('copy');
}

function startTimer(ref, cb) {
  const seqnum = ref.current;
  setTimeout(() => {
    if (ref.current === seqnum) {
      console.log(`TIMER seqnum ${seqnum} vs ${ref.current}... resetting`);
      cb();
    } else {
      console.log(`TIMER seqnum ${seqnum} vs ${ref.current}... STALE`);
    }
  }, 60000);
}

/*
  Note: This is quite messy but leaving it and moving on.
  Maybe hooks isn't the way to do all of this, or maybe it can all be made simpler.
  In order to clear the page after a minute, whenever the user does the following, we increment
  the seqnum rev (seqnum.current += 1), and then start a timer.
  The timer when it expires checks if the seqnum has incremented, and if so it does nothing.
 */
export default function App() {
  // Use this seqnum to force a new BoxedInput control instance
  const [reloadSeqnum, setReloadSeqnum] = useState(0);
  // This is the id they need to type in, like 'ABC123'
  const [snapId, setSnapId] = useState('');
  // This is the value we fetch from the backend
  const [snapText, setSnapText] = useState('');
  // Set this once the clipboard has been updated
  // TODO: read it back to confirm?
  const [showCopyTextFeedback, setShowCopyTextFeedback] = useState(false);
  // Loading indicator while fetching the snaptext for the type in snapId
  const [loading, setLoading] = useState(false);
  // Error if snapId not found
  const [fetchError, setFetchError] = useState('');
  // We need to use a dom element to copy text from
  const copyTextRef = useRef();
  // We need to use a dom element to copy "empty" text from to clear clipboard
  const emptyTextRef = useRef();
  // Use this to ignore stale enter key presses
  const seqnum = useRef(0);

  //---------------------------------------------------------------------------
  // Just for debugging, fetch all and write to console
  //---------------------------------------------------------------------------
  useEffect(() => {
    fetch(getApiUrl()).then((resp) => {
      return resp.json()
    }).then((snapIDs) => {
      console.log('current snap ids', snapIDs);
    });
  }, []);

  //---------------------------------------------------------------------------
  // onReload - Clear out all, plus make sure BoxedInput control is replaced
  //---------------------------------------------------------------------------
  const onReload = useCallback((v) => {
    console.log('onReload()', v);
    setReloadSeqnum(reloadSeqnum + 1);
    setSnapId('');
    setSnapText('');
    setFetchError('');
    setShowCopyTextFeedback(false);
    emptyClipboard(emptyTextRef);
    seqnum.current += 1;
  }, [reloadSeqnum, emptyTextRef, seqnum]);

  //---------------------------------------------------------------------------
  // OnEnter -- fetch the text from the backend, based on the id
  //---------------------------------------------------------------------------
  const onEnter = useCallback(async () => {
    emptyClipboard(emptyTextRef);
    seqnum.current += 1;
    const currentSeq = seqnum.current;
    if (snapId.length < 6) {
      console.log('onEnter()... ignore. Need 6 chars.');
      return;
    }
    console.log('onEnter()');
    setLoading(true);
    setFetchError('');
    await fetchText(snapId).then((result) => {
      if (seqnum.current === currentSeq) {
        console.log('onEnter()... done');
        setLoading(false);
        if (result.Error) {
          setFetchError(result.Error);
        } else {
          setSnapText(result.text);
        }
        seqnum.current += 1;
        startTimer(seqnum, onReload);
      } else {
        console.log('onEnter()... STALE');
      }
    });
  }, [seqnum, snapId, emptyTextRef, onReload]);

  //---------------------------------------------------------------------------
  // onChange - Keep snapId updated as we get changes from the BoxedInput control
  //---------------------------------------------------------------------------
  const onChange = useCallback((v) => {
    console.log('onChange()', v);
    setSnapId(v);
    seqnum.current += 1;
    startTimer(seqnum, onReload);
  }, [seqnum, onReload]);

  //---------------------------------------------------------------------------
  // onCopyText -- get our snapText onto the clipboard using a dom element
  //---------------------------------------------------------------------------
  const onCopyText = useCallback(() => {
    copyTextRef.current.select();
    document.execCommand('copy');
    setShowCopyTextFeedback(true);
    seqnum.current += 1;
    startTimer(seqnum, onReload);
  }, [seqnum, onReload]);

  //---------------------------------------------------------------------------
  // Show the edit view
  //---------------------------------------------------------------------------
  if (!snapText) {
    const enterDisabled = snapId.length < 6;
    return (
      <div className="condenser">
        <div className="reload-bar">
          <div
            className="reload-button"
            onClick={onReload}
          />
        </div>
        <div className="content-bar">
          <div className="content">
            <div className="snaptext-label">
              Enter snap text
            </div>
            <BoxedInput
              key={reloadSeqnum}
              amount={6}
              onChange={onChange}
              onEnter={onEnter}
              inputRegExp={/^[0-9a-zA-Z]$/}
              autoFocus
            />
            <div className="enter-button-container">
              <div
                className={cn('enter-button', { disabled: enterDisabled })}
                onClick={onEnter}
              >
                <div className="text">
                  Enter
                </div>
              </div>
            </div>
            {fetchError && (
              <div className="fetch-error">
                Invalid ID
              </div>
            )}
            {loading && (
              <div className="loading">
                loading
              </div>
            )}
          </div>
        </div>
        <div
          style={{ overflow: 'hidden', width: '1px', height: '1px' }}
          ref={emptyTextRef}
        >
          {'DOH!'}
        </div>
      </div>
    );
  }

  //---------------------------------------------------------------------------
  // Show the copy view
  //---------------------------------------------------------------------------
  return (
    <div className="condenser">
      <div className="reload-bar">
        <div
          className="reload-button"
          onClick={onReload}
        />
      </div>
      <div className="content-bar">
        <div className="content">
          <div className="snaptext-label">
            Entered snap text
          </div>
          <div className="snaptext-value snap-id">
            {snapId}
          </div>
          <div className="snaptext-label">
            Text
          </div>
          <textarea
            className="snaptext-value snap-text"
            ref={copyTextRef}
          >
            {snapText}
          </textarea>
          <div className="enter-button-container">
            <div
              className="enter-button"
              onClick={onCopyText}
            >
              <div className="text">
                Copy
              </div>
            </div>
          </div>
          {showCopyTextFeedback && (
            <div className="text-copied">
              Text Copied
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="loading">
          loading
        </div>
      )}
      <div
        style={{ overflow: 'hidden', width: '1px', height: '1px' }}
        ref={emptyTextRef}
      >
        {'DOH!'}
      </div>
    </div>
  );
}

/*
      <table className="snap-ids">
        <thead>
          <tr><th>ID</th><th>Text (click to copy)</th></tr>
        </thead>
        <tbody>
        {snaps.sort((a, b) => a.id < b.id ? -1 : 1).map((snap) => {
          return (<tr id={snap.id.replace(' ', '-')} key={snap.id}>
            <td className="snap-id">{snap.id}</td>
            <td className="snap-text" onClick={this.copyText}>{snap.text}</td>
          </tr>);
        })}
        </tbody>
        <tfoot>
          <tr>
            <td/>
            <td className="copied-feedback">{showCopyTextFeedback && "Text Copied!"}</td>
          </tr>
        </tfoot>
      </table>
 */